/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { ObservabilityEvent, TalerAuthenticationHttpClient, TalerBankConversionHttpClient, TalerCoreBankHttpClient, TalerMerchantInstanceHttpClient, TalerMerchantManagementHttpClient } from "@gnu-taler/taler-util";

type Listener<Event> = (e: Event) => void;
type Unsuscriber = () => void;
export type Subscriber<Event> = (fn: Listener<Event>) => Unsuscriber;

export class ActiviyTracker<Event> {
  private observers = new Array<Listener<Event>>();
  constructor() {
    this.notify = this.notify.bind(this)
    this.subscribe = this.subscribe.bind(this)
  }
  notify(data: Event) {
    this.observers.forEach((observer) => observer(data))
  }
  subscribe(func: Listener<Event>): Unsuscriber {
    this.observers.push(func);
    return () => {
      this.observers.forEach((observer, index) => {
        if (observer === func) {
          this.observers.splice(index, 1);
        }
      });
    };
  }
}

/**
 * build http client with cache breaker due to SWR
 * @param url
 * @returns
 */
export interface APIClient<T, C> {
  getRemoteConfig(): Promise<C>;
  VERSION: string;
  lib: T,
  onActivity: Subscriber<ObservabilityEvent>;
  cancelRequest(id: string): void;
}

export interface MerchantLib {
  instance: TalerMerchantManagementHttpClient;
  authenticate: TalerAuthenticationHttpClient;
  subInstanceApi: (instanceId: string) => MerchantLib;
}

export interface BankLib {
  bank: TalerCoreBankHttpClient;
  conversion: TalerBankConversionHttpClient;
  auth: (user: string) => TalerAuthenticationHttpClient;
}

